import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image";
import globalStyle from "../styles/global.module.css";

const BlogPage =  ({data, author}) => {
  const authorPosts = author ?
    data.allMarkdownRemark.edges.filter(post => post.node.frontmatter.author === author) : data.allMarkdownRemark.edges;

  return (
    <Layout className="site-content">
      <div className="blog-post-container">
        <div className="blog-post">
          {authorPosts.map(({node}) => (
            <div key={node.id }>
              <div className="post-thumbnail">
                <Link to={node.frontmatter.path} >
                  <Img  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                </Link>
              </div>

              <div className="blog-post-content">
                <div className="entry-meta">
                  <Link to={node.frontmatter.path}>
                    <span >{node.frontmatter.date }</span>
                  </Link>
                  <Link className="link-to-page"  to={"/author/" + node.frontmatter.author}>
                    <span>{node.frontmatter.author}</span>
                  </Link>
                </div>

                <h1 className={globalStyle.entryTitle}>{node.frontmatter.title}</h1>

                <div className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark (
        filter: {frontmatter: {posttype: {eq: "blogpost"}}},
        sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            author
            date(formatString: "DD MMMM, YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;

export default BlogPage
